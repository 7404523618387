<template>
  <div id="ocrResultMsg">

    <b-table v-if="ocr_result_success === 1"
        striped
        class="mb-3"
        bordered="bordered"
        small
        hover
        head-variant="dark"
        :items="items"></b-table>

    <h6 class="mt-2">Ruwe data</h6>
    <div class="mb-1 ocr_result_msg"
        v-html="ocr_result_msg_copy"></div>

    <b-button-group v-if="ocr_result_success === 1"
        class="mt-3 mb-1">
      <b-button variant="secondary"
          @click="showModal()">Aanvullen
      </b-button>

      <b-button v-if="show_send"
          href="#"
          variant="primary"
          @click="send()">Verzenden
      </b-button>
    </b-button-group>

    <b-button-group class="mt-1 mb-1">
      <b-button variant="default"
          @click="retry()">Overnieuw
      </b-button>
    </b-button-group>

    <!-- MODAL -->
    <b-modal ref="my-modal"
        class="modal-scoped"
        hide-header
        hide-footer
        size="xl"
        title="Opties">

      <result-raw-correction :ocr_text_copy_view="ocr_text_copy_view"
          @saveEdit="saveEditParent"
          @cancelEdit="cancelEditParent"></result-raw-correction>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

import {RequestHelper} from "@/entities/helpers/RequestHelper";
import {DataHelper} from "@/entities/helpers/DataHelper";
import ResultRawCorrection from "@/components/webcam/resultRaw/ResultRawCorrection";
import cloneDeep from "lodash/cloneDeep";
import ltrim from "locutus/php/strings/ltrim";

export default {
  name      : "ResultRaw",
  components: {
    ResultRawCorrection
  },
  props     : [
    'ocr_text',
    'ocr_result_row_id',
    'ocr_result_msg',
    'ocr_result_success',
  ],
  data() {
    const that = this;

    return {
      show_send          : false,
      ocr_text_copy_view : {},
      ocr_result_msg_copy: "",
      items              : []
    }
  },
  created   : async function() {
    const that = this;

    that.requestHelper = new RequestHelper(that);
    that.dataHelper    = new DataHelper(that);

    that.ocr_result_msg_copy = ltrim(that.ocr_result_msg.trim(), '<br />').trim();

    that.ocr_text_copy_view = cloneDeep(that.ocr_text)
    that.items              = [
      {Label: 'Model', Waarde: that.ocr_text_copy_view.model},
      {Label: 'Artikel Nummer', Waarde: that.ocr_text_copy_view.artikelNummer},
      {Label: 'Versie nummer', Waarde: that.ocr_text_copy_view.versieNummer},
      {Label: 'Serie nummer', Waarde: that.ocr_text_copy_view.serieNummer}
    ];
  },
  methods   : {
    showModal() {
      const that = this;

      that.$refs['my-modal'].show()
    },
    /**
     */
    cancelEditParent() {
      const that = this;

      console.log('cancelEditParent');

      that.$refs['my-modal'].hide()
    },
    /**
     *
     * @param ocrTextCopyEdit
     */
    saveEditParent(ocrTextCopyEdit) {
      const that = this;

      that.show_send = true;

      that.ocr_text_copy_view = ocrTextCopyEdit;

      console.log('saveEditParent', ocrTextCopyEdit, that.ocr_text_copy_view);

      that.$refs['my-modal'].hide()
    },
    /**
     *
     * @returns {Promise<void>}
     */
    async send() {
      const that = this;

      // VANAF HET BEGIN
      let ocrTextCleaned = cloneDeep(that.ocr_text_copy_view);
      delete ocrTextCleaned.textFound;
      delete ocrTextCleaned.textFoundLinesArrayArray;

      ocrTextCleaned.ocr_result_msg   = ltrim(that.ocr_result_msg.trim(), '<br />').trim();
      ocrTextCleaned.ocr_result_array = that.ocr_text_copy_view.textFoundLinesArrayArray;

      let reqData = {
        ocr_result_row_id : that.ocr_result_row_id,
        ocr_text_collected: ocrTextCleaned
      };

      const resOcrResultFinal = await that.requestHelper.queryPost(
          reqData,
          'ocr/result_final'
      );

      console.log(
          'send',
          {
            req: reqData,
            res: resOcrResultFinal
          }
      );

      that.$emit('refresh_state_local', [
        'resume_camera', {}
      ]);
    },
    /**
     *
     * @returns {Promise<void>}
     */
    async retry() {
      const that = this;

      console.log(
          'retry'
      );

      that.$emit('refresh_state_local', [
        'resume_camera', {}
      ]);
    },

  }
}
</script>

<style scoped>
.modal-scoped {
  z-index : 99999;
}

.ocr_result_msg {
  font-size : 10px;
  max-height: 187px;
  overflow: hidden;
}

.btn-group {
  width : 100%;
}
</style>