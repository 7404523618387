<template>
  <div id="webcam-app">
    <!--        <h1>This is an {{ name }} page</h1>-->
    <!--    <Video msg="123"></Video>-->
    <!--    <WebcamUI msg="123"></WebcamUI>-->

    <!-- webcamControl -->
    <div v-show="photo_taking_phase === CAM_OFF || photo_taking_phase === CAM_ON_BEFORE"
        class="form-control webcam-start shadow"
        :class="webcamControlClass">
      <label class="form-switch">
        <input type="checkbox"
            :class="{ checked : webcam_active }"
            @input="webcamControlSwitch"
            id="webcam-switch">

        <i></i>
        <span>{{ webcam_switch_caption }}</span>
      </label>

      <!-- choose camera -->
      <div v-show="photo_taking_phase === CAM_ON_BEFORE"
          id="cameraFlipList"></div>
    </div>

    <!-- ocr_result_msg -->
    <!--    <div id="ocrResultMsg"-->
    <!--        v-if='ocr_result_msg !==""'>-->
    <!--      <span v-html="ocr_result_msg"></span>-->
    <!--    </div>-->

    <result_raw v-if='ocr_result_msg !==""'
        :ocr_text="ocr_text"
        :ocr_result_row_id="ocr_result_row_id"
        :ocr_result_msg="ocr_result_msg"
        :ocr_result_success="ocr_result_success"
        @method_catch_all="methodCatchAll"
        @refresh_state_local="refreshStateLocal"></result_raw>

    <!-- error_msg -->
    <div v-if='error_msg !==""'
        id="errorMsg"
        class="col-12 col-md-6 alert-danger">
      {{ error_msg }}

      <button @click="btnCloseError()"
          class="btn btn-primary ml-3">OK
      </button>
    </div>

    <div v-show="webcam_active"
        :class="modalClass"
        class="md-modal md-effect-12">
      <div id="app-panel"
          class="app-panel md-content row p-0 m-0">

        <!-- webcam-container -->
        <div id="webcam-container"
            class="webcam-container col-12 p-0 m-0">

          <video v-show="photo_taking_phase === CAM_ON_BEFORE"
              id="webcam"
              autoplay
              playsinline
              width="1280"
              height="720"></video>

          <canvas v-show="photo_processing_phase === 1
          || photo_taking_phase === CAM_ON_AFTER"
              id="canvas"></canvas>
        </div>

        <!-- cameraControls -->
        <div v-show="photo_taking_phase === CAM_ON_BEFORE
        || photo_processing_phase === 1
        || photo_taking_phase === CAM_ON_AFTER"
            id="cameraControls"
            class="cameraControls">

          <!-- A -->
          <a v-show="photo_taking_phase === CAM_ON_BEFORE
          && photo_processing_phase === 0"
              @click="btnExitCamera()"
              href="#"
              title="Exit App"><i class="material-icons">arrow_back</i></a>

          <a v-show="photo_taking_phase === CAM_ON_BEFORE
          && photo_processing_phase === 0"
              @click="btnTakePhoto()"
              href="#"
              title="Take Photo"><i class="material-icons">camera_alt</i></a>

          <a style="opacity: 0;"
              v-show="photo_taking_phase === CAM_ON_BEFORE
          && photo_processing_phase === 0"
              @click="btnTakePhoto()"
              href="#"
              title="Take Photo"><i class="material-icons">camera_alt</i></a>

          <!-- B -->
          <a v-show="photo_processing_phase === 1"
              href="#"
              title="Take Photo"><i class="material-icons fas fa-spinner fa-spin"></i></a>

          <!-- C -->
          <a v-show="photo_taking_phase === CAM_ON_AFTER"
              @click="btnExitCamera()"
              href="#"
              title="Exit App"><i class="material-icons">arrow_back</i></a>

          <a v-show="photo_taking_phase === CAM_ON_AFTER"
              @click="btnResumeCamera()"
              href="#"
              title="Resume Camera"><i class="material-icons">camera</i></a>

          <a style="opacity: 0;"
              v-show="photo_taking_phase === CAM_ON_AFTER"
              @click="btnResumeCamera()"
              href="#"
              title="Resume Camera"><i class="material-icons">camera</i></a>
        </div>

        <!-- webcam-overlay -->
        <div class="webcam-overlay">
          <div class="webcam-overlay-bar webcam-overlay-bar-h webcam-overlay-bar-top">
            <i class="webcam-overlay-pointer webcam-overlay-pointer-top far fa-hand-point-left"></i>
          </div>
          <div class="webcam-overlay-bar webcam-overlay-bar-h webcam-overlay-bar-bottom">
            <i class="webcam-overlay-pointer webcam-overlay-pointer-bottom far fa-hand-point-right"></i>
          </div>
          <div class="webcam-overlay-bar webcam-overlay-bar-v webcam-overlay-bar-left"></div>
          <div class="webcam-overlay-bar webcam-overlay-bar-v webcam-overlay-bar-mid"></div>
          <div class="webcam-overlay-bar webcam-overlay-bar-v webcam-overlay-bar-right"></div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

import {Ocr} from "@/entities/webcam/Ocr";
import {WebcamEasy} from "@/entities/webcam/WebcamEasy";
// import Video from "@/components/webcam/Video";
import $ from 'jquery';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import {RequestHelper} from "@/entities/helpers/RequestHelper";
import ResultRaw from "@/components/webcam/ResultRaw";

let webcamEasy = undefined;
let ocr        = undefined;

export default {
  name      : 'webcam',
  components: {
    result_raw: ResultRaw
    // Video
  },
  data() {
    // Fail to start camera, please allow permission to access camera
    return {
      photo_taking_phase    : 0,
      photo_processing_phase: 0,
      CAM_OFF               : 0,
      CAM_ON_BEFORE         : 1,
      CAM_ON_AFTER          : 2,
      webcam_active         : false,
      webcam_switch_caption : 'Klik om te starten',
      ocr_result_msg        : '',
      ocr_result_success    : -1,
      ocr_text              : {},
      ocr_result_row_id     : -1,
      error_msg             : '',
      name                  : 'webcam-name-6',
    }
  },
  created   : async function() {
    const that = this;

    that.request = new RequestHelper(that);
  },
  mounted   : async function() {
    const that = this;

    const webcamElement = document.getElementById('webcam');
    const canvasElement = document.getElementById('canvas');

    that.webcamEasy = new WebcamEasy(webcamElement, 'environment', canvasElement);
    that.ocr        = new Ocr();
  },
  watch     : {
    /**
     */
    webcam_active: function(val, oldVal) {
      const that = this;

      if (val) {
        that.photo_taking_phase    = that.CAM_ON_BEFORE;
        that.webcam_switch_caption = 'aan'

        that.webcamEasy.start()
            .then(result => {
              that.cameraStarted();
            })
            .catch(err => {
              //  try again
              that.webcamEasy.start().then(() => {});
              that.error_msg = err;
            });
      }
      else {
        that.photo_taking_phase    = that.CAM_OFF;
        that.webcam_switch_caption = 'Klik om te starten'
        that.webcamEasy.stop();
      }
    },
  },
  computed  : {
    /**
     */
    webcamControlClass() {
      const that = this;

      let className = 'webcam-off';
      if (that.webcam_active) {
        className = 'webcam-on';
      }

      return className;
    },
    /**
     */
    modalClass() {
      const that = this;

      let className = '';
      if (that.webcam_active) {
        className = 'md-show';
      }

      return className;
    }
  },
  methods   : {
    /**
     *
     * @param args
     * @returns {Promise<void>}
     */
    async methodCatchAll(args) {
      if (args[1] === undefined) {
        args[1] = {};
      }

      // console.log(['methodCatchAll +1'], args[0], args[1]);

      this.$emit('method_catch_all', [args[0], args[1]])
    },
    /**
     *
     * @param args
     * @returns {Promise<boolean>}
     */
    async refreshStateLocal(args) {
      // ### ROOT ###
      const that = this;
      if (args[1] === undefined) {
        args[1] = {};
      }

      // todo env
      // if (!isProduction) {
      console.log(['SCAN - refreshStateLocal'], args[0], args[1]);
      // }

      if (args[0] === 'resume_camera') {
        that.btnResumeCamera();
      }

      return true;
    },
    /**
     *
     */
    webcamControlSwitchDirect() {
      const that = this;

      that.webcam_active = !that.webcam_active;
    },
    /**
     *
     */
    webcamControlSwitch: debounce(function(e) {
      const that = this;

      that.webcamControlSwitchDirect();
    }, 50),
    /**
     *
     */
    btnCloseError() {
      const that = this;

      that.error_msg = "";
    },
    /**
     *
     */
    btnExitCamera() {
      const that = this;

      that.ocr_result_msg = '';

      that.photo_taking_phase = that.CAM_OFF;
      that.webcamControlSwitchDirect();
    },
    /**
     *
     */
    btnResumeCamera() {
      const that = this;

      that.ocr_result_msg = '';

      that.webcamEasy.stream()
          .then(facingMode => {
            that.photo_taking_phase = that.CAM_ON_BEFORE;
          });
    },
    /**
     *
     */
    async btnTakePhoto() {
      const that = this;

      that.photo_processing_phase = 1;

      const snapResult = that.webcamEasy.snap();

      // # get OCR result
      const {ocrResultRowId, ocrText} = await that.ocr.doOCR(snapResult.data_url);
      // that.webcamEasy.stop(); // todo if remove - instant resume
      that.photo_taking_phase         = that.CAM_ON_AFTER;
      that.photo_processing_phase     = 0;

      that.ocr_text          = ocrText;
      that.ocr_result_row_id = ocrResultRowId;
      console.log('webcam : ocrText', ocrText, ocrResultRowId);

      let ocrResultMsg = '';
      ocrResultMsg += `${ocrText.textFound.textFoundLines}`;

      that.ocr_result_success = 1;
      if (ocrResultMsg === '') {
        that.ocr_result_success = 0;
        ocrResultMsg            = 'Geen tekst gevonden!<br /><br />Probeer het nog een keer<br /><br />';
        console.error('webcam : ocrText >>', ocrResultMsg, ocrResultRowId);
      }
      that.ocr_result_msg = ocrResultMsg;
    },
    /**
     *
     * @returns {Promise<void>}
     */
    cameraStarted() {
      const that = this;

      console.log('cameraStarted')

      // todo to vue
      $("#cameraFlipList").empty();

      // TODO SET TO 1
      // todo to vue?
      // todo to vue?
      // todo to vue?
      // todo to vue?
      console.log('cam-list-1', that.webcamEasy.webcamList);
      if (that.webcamEasy.webcamList.length > 1) {
        let camIndex = 0;
        let camLabel = "";

        let counter = 1;
        for (let cam of that.webcamEasy.webcamList) {
          if (
              (cam.label.toLowerCase().includes('back'))
          ) {
            // todo to vue?
            $("#cameraFlipList").append(
                `<div class="btn btn-primary btn-camera-flip"
                  data-index="${camIndex}"
                  data-label="${cam.label}">
                  <i class="fas fa-camera"></i>&nbsp; : ${counter}
                </div>`
            );

            counter++;
          }

          // todo remove 'HD'
          else if (
              (cam.label.toLowerCase().includes('hd'))
          ) {
            $("#cameraFlipList").append(
                `<div class="btn btn-primary btn-camera-flip"
                  data-index="${camIndex}"
                  data-label="${cam.label}">
                  <i class="fas fa-camera"></i>&nbsp; : ${counter}
                </div>`
            );

            counter++;
          }
          camIndex++;
        }

        // todo to vue
        $('.btn-camera-flip').off();
        $('.btn-camera-flip').on("click", function() {
          const $that = $(this);

          console.log('cam-list-2', that.webcamEasy.webcamList, $that.attr('data-index'));
          that.webcamEasy.setCameraIndex(parseInt($that.attr('data-index')), $that.attr('data-label'));
          that.webcamEasy.stop();
          that.webcamEasy.start();
        });
      }
    }
  }
};
</script>

<style lang="scss"
    scoped>
h1 {
  color: orange;
}
</style>