/* eslint-disable no-mixed-spaces-and-tabs,no-unused-vars */
export class WebcamEasy {
	constructor(webcamElement, facingMode = 'environment', canvasElement = null) {
		this._factor        = 3;
		this._webcamElement = webcamElement;
		// this._webcamElement.width  = this._webcamElement.width || 640;
		// this._webcamElement.height = this._webcamElement.height || this._webcamElement.width * (3 / 4);

		this._webcamElement.width  = this._webcamElement.width * this._factor;
		this._webcamElement.height = this._webcamElement.height * this._factor;

		this._facingMode       = facingMode;
		this._cameraIndex      = -1;
		this._webcamList       = [];
		this._streamList       = [];
		this._selectedDeviceId = '';
		this._canvasElement    = canvasElement;
	}

	get facingMode() {
		return this._facingMode;
	}

	set facingMode(value) {
		this._facingMode = value;
	}

	get cameraIndex() {
		return this._cameraIndex;
	}

	set cameraIndex(value) {
		this._cameraIndex = value;
	}

	get webcamList() {
		return this._webcamList;
	}

	get webcamCount() {
		return this._webcamList.length;
	}

	get streamList() {
		return this._streamList;
	}

	set streamList(value) {
		this._streamList = value;
	}

	get selectedDeviceId() {
		return this._selectedDeviceId;
	}

	/* Get all video input devices info */
	getVideoInputs(mediaDevices) {
		this._webcamList = [];
		mediaDevices.forEach(mediaDevice => {
			if (mediaDevice.kind === 'videoinput') {
				this._webcamList.push(mediaDevice);
			}
		});
		if (this._webcamList.length === 1) {
			this._facingMode = 'environment';
		}
		return this._webcamList;
	}

	/* Get media constraints */
	getMediaConstraints() {
		let videoConstraints = {};

		videoConstraints.width  = this._webcamElement.width; // min max ideal
		videoConstraints.height = this._webcamElement.height

		if (this._selectedDeviceId === '') {
			videoConstraints.facingMode = this._facingMode;
		}
		else {
			videoConstraints.deviceId = {exact: this._selectedDeviceId};
		}
		return {
			video: videoConstraints,
			audio: false
		};
	}

	/* Select camera based on facingMode */
	selectCamera() {
		const that = this;
		// console.log('this._webcamList WEBCAM', this._webcamList);
		// console.log('that.cameraIndex', that.cameraIndex);

		let camIndex = 0;
		for (let webcam of this._webcamList) {
			if (that.cameraIndex !== -1) {
				if (camIndex === that.cameraIndex) {
					this._selectedDeviceId = webcam.deviceId;
					// console.log('SELECTED', webcam.label);
					break;
				}
			}
			else {
				// give selection to more 'backs' - less wide!
				if (
					(this._facingMode === 'user' && webcam.label.toLowerCase().includes('front'))
					||
					(this._facingMode === 'environment' && webcam.label.toLowerCase().includes('back'))
				) {
					this._selectedDeviceId = webcam.deviceId;
					break;
				}
			}

			camIndex++;
		}
	}

	/* Change Facing mode and selected camera */
	flip() {
		this._facingMode                    = (this._facingMode === 'user') ? 'environment' : 'user';
		this._webcamElement.style.transform = "";
		this.selectCamera();
	}

	setCameraIndex(cameraIndexInput, label) {
		this.cameraIndex = cameraIndexInput;
		console.log('cameraIndex', {
			index: this.cameraIndex,
			label: label
		});

		this.selectCamera();
	}

	/*
	 1. Get permission from user
	 2. Get all video input devices info
	 3. Select camera based on facingMode
	 4. Start stream
	 */
	async start(startStream = true) {
		let that = this;

		return new Promise((resolve, reject) => {
			this.stop();

			console.log('this.getMediaConstraints()', this.getMediaConstraints());
			navigator.mediaDevices.getUserMedia(this.getMediaConstraints()) // get permisson from user
				// eslint-disable-next-line no-mixed-spaces-and-tabs
				     .then(stream => {
					     this._streamList.push(stream);

					     this.info() //get all video input devices info
					         .then(webcams => {
						         this.selectCamera();   // select camera based on facingMode

						         if (startStream) {
							         this.stream()
							             .then(facingMode => {
								             resolve(this._facingMode);
							             })
							             .catch(error => {
								             that.start().then(() => {
									             resolve(this._facingMode);
								             }).catch(error => {
									             console.error('error', error);
									             reject(error);
								             });
							             });
						         }
						         else {
							         resolve(this._selectedDeviceId);
						         }

					         })
					         .catch(error => {
						         reject(error);
					         });
				     })
				     .catch(error => {
					     reject(error);
				     });

		});
	}

	/* Get all video input devices info */
	async info() {
		return new Promise((resolve, reject) => {
			navigator.mediaDevices.enumerateDevices()
			         .then(devices => {
				         this.getVideoInputs(devices);
				         resolve(this._webcamList);
			         })
			         .catch(error => {
				         reject(error);
			         });
		});
	}

	/* Start streaming webcam to video element */
	async stream() {
		return new Promise((resolve, reject) => {
			navigator.mediaDevices.getUserMedia(this.getMediaConstraints())
			         .then(stream => {
				         this._streamList.push(stream);
				         this._webcamElement.srcObject = stream;

				         if (this._facingMode === 'user') {
					         // this._webcamElement.style.transform = "scale(-1,1)";
				         }
				         this._webcamElement.play();

				         resolve(this._facingMode);
			         })
			         .catch(error => {
				         console.log(error);
				         reject(error);
			         });
		});
	}

	/* Stop streaming webcam */
	stop() {
		this._webcamElement.srcObject = null;
		this._streamList.forEach(stream => {
			stream.getTracks().forEach(track => {
				stream.removeTrack(track);
				track.stop();
			});
		});
	}

	snap() {
		const that = this;

		if (this._canvasElement != null) {
			this._canvasElement.width  = this._webcamElement.scrollWidth * this._factor;
			this._canvasElement.height = this._webcamElement.scrollHeight * this._factor;

			// # set final sizes
			if (this._canvasElement.width > 1920 || this._canvasElement.height > 1920) {

				let maxSizeFactor = 1;
				if (this._canvasElement.width > this._canvasElement.height) {
					// laptops
					maxSizeFactor = 3800 / this._canvasElement.width; // resize to
				}
				else {
					// mobiles
					maxSizeFactor = 3800 / this._canvasElement.height; // resize to
				}

				// console.log('A1', maxSizeFactor, this._webcamElement.scrollWidth, this._webcamElement.scrollHeight);
				// console.log('A2', maxSizeFactor, this._canvasElement.width, this._canvasElement.height);

				this._canvasElement.width  = this._canvasElement.width * maxSizeFactor;
				this._canvasElement.height = this._canvasElement.height * maxSizeFactor;

				// console.log('B', maxSizeFactor, this._canvasElement.width, this._canvasElement.height);
			}

			let context = this._canvasElement.getContext('2d');

			if (this._facingMode === 'user') {
				context.translate(this._canvasElement.width, 0);
				// context.scale(-1, 1);
			}

			context.clearRect(0, 0, this._canvasElement.width, this._canvasElement.height);
			context.drawImage(this._webcamElement, 0, 0, this._canvasElement.width, this._canvasElement.height);

			// contrast
			let origBits = context.getImageData(0, 0, this._canvasElement.width, this._canvasElement.height);
			that._contrastImage(origBits, .15);
			context.putImageData(origBits, 0, 0);

			const dataURL = this._canvasElement.toDataURL('image/jpeg', 0.9); // compression


			return {data_url: dataURL};
		}
		else {
			throw "canvas element is missing";
		}
	}

	/**
	 *
	 * @param imageData
	 * @param contrast
	 * @returns {*}
	 * @private
	 */
	_contrastImage(imageData, contrast) {  // contrast input as percent; range [-1..1]
		let data   = imageData.data;  // Note: original dataset modified directly!
		contrast *= 255;

		let factor = (contrast + 255) / (255.01 - contrast);  //add .1 to avoid /0 error.

		for (let i = 0; i < data.length; i += 4) {
			data[i]     = factor * (data[i] - 128) + 128;
			data[i + 1] = factor * (data[i + 1] - 128) + 128;
			data[i + 2] = factor * (data[i + 2] - 128) + 128;
		}

		return imageData;  //optional (e.g. for filter function chaining)
	}

	/**
	 *
	 * @param dataURI
	 * @returns {Blob}
	 * @private
	 */
	_dataURItoBlob(dataURI) {
		// https://docs.microsoft.com/en-us/answers/questions/66130/azure-computer-vision-cognitive-services-how-to-pe.html

		// convert base64 to raw binary data held in a string
		// doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
		let byteString = atob(dataURI.split(',')[1]);

		// separate out the mime component
		let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

		// write the bytes of the string to an ArrayBuffer
		let ab = new ArrayBuffer(byteString.length);

		// create a view into the buffer
		let ia = new Uint8Array(ab);

		// set the bytes of the buffer to the correct values
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		// write the ArrayBuffer to a blob, and you're done
		return new Blob([ab], {type: mimeString});
	}
}