<template>
  <div>

    <table class="table-raw-correction">
      <tr>
        <th>
          <span style="font-weight: normal;">'Model'</span>
          <br />
          <em>{{ ocr_text_copy_edit.model }}</em>
          <br />
        </th>
        <td>
          <b-form-group label="Correct?">
            <b-form-radio-group id="radio-group-1"
                v-model="ocr_text_copy_edit.model_res_correct"
                :options="result_is_correct"
                name="radio-options-1"></b-form-radio-group>
          </b-form-group>
        </td>
      </tr>

      <tr>
        <th>
          <span style="font-weight: normal;">'Artikelnummer'</span>
          <br />
          <em>{{ ocr_text_copy_edit.artikelNummer }}</em>
          <br />
        </th>
        <td>
          <b-form-group label="">
            <b-form-radio-group id="radio-group-2"
                v-model="ocr_text_copy_edit.artikel_nummer_res_correct"
                :options="result_is_correct"
                name="radio-options-2"></b-form-radio-group>
          </b-form-group>
        </td>
      </tr>

      <tr>
        <th>
          <span style="font-weight: normal;">'Versienummer'</span>
          <br />
          <em>{{ ocr_text_copy_edit.versieNummer }}</em>
          <br />
        </th>
        <td>
          <b-form-group label="">
            <b-form-radio-group id="radio-group-3"
                v-model="ocr_text_copy_edit.versie_nummer_res_correct"
                :options="result_is_correct"
                name="radio-option-3"></b-form-radio-group>
          </b-form-group>
        </td>
      </tr>

      <tr>
        <th>
          <span style="font-weight: normal;">'Serienummer'</span>
          <br />
          <em>{{ ocr_text_copy_edit.serieNummer }}</em>
          <br />
        </th>
        <td>
          <b-form-group label="">
            <b-form-radio-group id="radio-group-4"
                v-model="ocr_text_copy_edit.serie_nummer_res_correct"
                :options="result_is_correct"
                name="radio-options-4"></b-form-radio-group>
          </b-form-group>
        </td>
      </tr>

      <tr>
        <th colspan="2">
          <hr />
        </th>
      </tr>

      <tr>
        <th>
          Product merk
        </th>
        <td>
          <b-form-select v-model="ocr_text_copy_edit.label_brand"
              :options="label_brands"></b-form-select>
        </td>
      </tr>

      <tr>
        <th>
          Product group
        </th>
        <td>
          <b-form-select v-model="ocr_text_copy_edit.label_product_group"
              :options="label_product_groups"></b-form-select>
        </td>
      </tr>

      <tr>
        <th>
          Gebruikersnaam
        </th>
        <td>
          <b-form-select v-model="ocr_text_copy_edit.scan_user_name"
              :options="scan_user_names"></b-form-select>
        </td>
      </tr>

      <tr>
        <th>
          Scan device brand
        </th>
        <td>
          <b-form-select v-model="ocr_text_copy_edit.scan_mobile_brand"
              :options="scan_mobile_brands"></b-form-select>
        </td>
      </tr>

      <tr>
        <th>
          Scan device camera
        </th>
        <td>
          <b-form-select v-model="ocr_text_copy_edit.scan_device"
              :options="scan_devices"></b-form-select>
        </td>
      </tr>

      <tr>
        <th>
          Locatie beschrijving
        </th>
        <td>
          <b-form-select v-model="ocr_text_copy_edit.scan_location_description"
              :options="scan_location_descriptions"></b-form-select>
        </td>
      </tr>

    </table>

    <b-button-group class="mt-3 mb"
        style="text-align: right; width: 100%;">
      <!--      <b-button variant="secondary"-->
      <!--          @click="cancelEdit()">Annuleren-->
      <!--      </b-button>-->

      <b-button href="#"
          variant="primary"
          @click="saveEdit()">Toepassen
      </b-button>
    </b-button-group>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

import {RequestHelper} from "@/entities/helpers/RequestHelper";
import {DataHelper} from "@/entities/helpers/DataHelper";
import cloneDeep from "lodash/cloneDeep";

export default {
  name   : "ResultRawCorrection",
  props  : [
    'ocr_text_copy_view'
  ],
  data() {
    const that = this;

    return {
      ocr_text_copy_edit        : {},
      result_is_correct         : [
        {value: 1, text: 'Ja'},
        {value: 0, text: 'Nee'}
      ],
      label_brands              : [
        {value: '-1', text: '--> Maak een keuze'},
        {value: 'ATAG', text: 'ATAG'},
        {value: 'ETNA', text: 'ETNA'},
        {value: 'Pelgrim', text: 'Pelgrim'},
        {value: 'ASKO', text: 'ASKO'},
        {value: 'Hisense', text: 'Hisense'},
      ],
      label_product_groups      : [
        {value: '-1', text: '--> Maak een keuze'},
        {value: 'Magnetron', text: 'Magnetron'},
        {value: 'Oven', text: 'Oven'},
        {value: 'Koelkast', text: 'Koelkast'},
        {value: 'Fornuis', text: 'Fornuis'},
      ],
      scan_user_names           : [
        {value: '-1', text: '--> Maak een keuze'},
        {value: 'Jarno Wieland', text: 'Jarno Wieland'},
        {value: 'Inge Jentink', text: 'Inge Jentink'},
        {value: 'Remco', text: 'Remco'},
        {value: 'Daan', text: 'Daan'},
        {value: 'Koen', text: 'Koen'},
      ],
      scan_mobile_brands        : [
        {value: '-1', text: '--> Maak een keuze'},
        {value: 'Apple', text: 'Apple'},
        {value: 'Samsung', text: 'Samsung'},
        {value: 'Huawei', text: 'Huawei'},
        {value: 'OnePlus', text: 'OnePlus'},
        {value: 'Sony', text: 'Sony'},
        {value: 'HP', text: 'HP'},
        {value: 'Dell', text: 'Dell'},
        {value: 'Anders..', text: 'Anders..'},
      ],
      scan_devices              : [
        {value: '-1', text: '--> Maak een keuze'},
        {value: 'Mobiel : normale camera', text: 'Mobiel : normale camera'},
        {value: 'Mobiel : groothoek camera', text: 'Mobiel : groothoek camera'},
        {value: 'Tablet', text: 'Tablet'},
        {value: 'Laptop : interne webcam', text: 'Laptop : interne webcam'},
        {value: 'Laptop : externe webcam', text: 'Laptop : externe webcam'},
      ],
      scan_location_descriptions: [
        {value: '-1', text: '--> Maak een keuze'},
        {value: 'Hoog contrast belichting', text: 'Hoog contrast belichting'},
        {value: 'Normaal verlicht', text: 'Normaal verlicht'},
        {value: 'Slecht verlicht', text: 'Slecht verlicht'},
        {value: 'Buiten : overdag', text: 'Buiten : overdag'},
        {value: 'Buiten : schemer', text: 'Buiten : schemer'},
      ],
    }
  },
  created: async function() {
    const that = this;

    that.requestHelper = new RequestHelper(that);
    that.dataHelper    = new DataHelper(that);

    that.ocr_text_copy_edit = cloneDeep(that.ocr_text_copy_view)

    // prefill
    if (that.ocr_text_copy_edit.scan_location_description === undefined) {
      that.ocr_text_copy_edit.scan_location_description = 'Normaal verlicht';
    }

    ////////
    // get and set cookies for ...
    if (that.ocr_text_copy_edit.scan_user_name === undefined) {
      that.ocr_text_copy_edit.scan_user_name = that.dataHelper.getCookie('scan_user_name');
    }
    if (that.ocr_text_copy_edit.scan_mobile_brand === undefined) {
      that.ocr_text_copy_edit.scan_mobile_brand = that.dataHelper.getCookie('scan_device_brand')
    }
    if (that.ocr_text_copy_edit.scan_device === undefined) {
      that.ocr_text_copy_edit.scan_device = that.dataHelper.getCookie('scan_device_type')
    }

    console.log('ResultRawCorrection', that.ocr_text_copy_view);
  },
  methods: {
    /**
     *
     * @returns {*}
     * @param row
     */
    getFilePathFullThumb: function(row) {
      const that = this;

      console.log('result row', row);

      return that.requestHelper.baseUrl + that.history_file_dir + row.file_name_max
    },
    /**
     *
     */
    saveEdit() {
      const that = this;

      console.log('saveEdit', that.ocr_text_copy_edit);

      // get scan result corrects
      that.ocr_text_copy_edit.result_correct_p = this._getResultCorrectP(that);

      // cleanup - always add to collection
      if (that.ocr_text_copy_edit.label_brand === undefined) {
        that.ocr_text_copy_edit.label_brand = null;
      }
      if (that.ocr_text_copy_edit.label_product_group === undefined) {
        that.ocr_text_copy_edit.label_product_group = null;
      }

      ////////
      // set cookies for 'scan_user_name, scan_mobile_brand'
      if (that.ocr_text_copy_edit.scan_user_name !== undefined) {
        that.dataHelper.setCookie('scan_user_name', that.ocr_text_copy_edit.scan_user_name, 360);
      }
      if (that.ocr_text_copy_edit.scan_mobile_brand !== undefined) {
        that.dataHelper.setCookie('scan_device_brand', that.ocr_text_copy_edit.scan_mobile_brand, 360);
      }
      if (that.ocr_text_copy_edit.scan_device !== undefined) {
        that.dataHelper.setCookie('scan_device_type', that.ocr_text_copy_edit.scan_device, 360);
      }

      this.$emit('saveEdit', that.ocr_text_copy_edit);
    },
    /**
     *
     */
    cancelEdit() {
      const that = this;

      this.$emit('cancelEdit', true);
    },
    /**
     *
     * @param that
     * @returns {number}
     */
    _getResultCorrectP  : function(that) {
      let resultCorrectP         = 0;
      let resCorrectTotalCount   = 4;
      let resCorrectSuccessCount = 0;
      if (that.ocr_text_copy_edit.model_res_correct !== undefined) {
        if (that.ocr_text_copy_edit.model_res_correct === 1) {
          resCorrectSuccessCount++;
        }
      }
      if (that.ocr_text_copy_edit.artikel_nummer_res_correct !== undefined) {
        if (that.ocr_text_copy_edit.artikel_nummer_res_correct === 1) {
          resCorrectSuccessCount++;
        }
      }
      if (that.ocr_text_copy_edit.versie_nummer_res_correct !== undefined) {
        if (that.ocr_text_copy_edit.versie_nummer_res_correct === 1) {
          resCorrectSuccessCount++;
        }
      }
      if (that.ocr_text_copy_edit.serie_nummer_res_correct !== undefined) {
        if (that.ocr_text_copy_edit.serie_nummer_res_correct === 1) {
          resCorrectSuccessCount++;
        }
      }

      if (resCorrectTotalCount > 0) {
        resultCorrectP = ((resCorrectSuccessCount * 100) / resCorrectTotalCount);
      }

      return resultCorrectP;
    },
  }
}
</script>

<style lang="scss"
    scoped>
img {
  width: 100%;
}

</style>

<style lang="scss">
.table-raw-correction {
  th, td {
    padding: 2px 5px;
    font-size: 12px;

    input, select {
      cursor: pointer;
    }

    label {
      span {
        position: relative;
        top: 3px;
      }
    }
  }

  legend {
    font-weight: bold;
  }
}
</style>